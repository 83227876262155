import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { selectUser } from '../../account/store/account.selectors';
import { AppState } from '../../store';
import { first } from 'rxjs';

const BoardToken = 'dd0da05b-b9f4-e948-2b42-2f7a8905496c';

@Component({
  selector: 'dialog-template',
  template: `
    <div class="dialog confirm alert alert-danger text-center feedback-dialog">
      <div class="dialog-text">
        <div data-canny style="width: 100%;" [ngClass]="{ loaded: isLoaded }" class="feedback-dialog-content"></div>
        <xp-loader *ngIf="!isLoaded"></xp-loader>
      </div>

      <div class="dialog-close" (click)="cancel()"></div>
    </div>
  `,
})
export class CannyDialogComponent {
  isLoaded = false;
  userInfo$ = this.store.select(selectUser);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CannyDialogComponent>,
    private store: Store<AppState>,
  ) {}

  ngAfterViewInit() {
    this.userInfo$.pipe(first()).subscribe((user) => {
      if (!user) return;
      window['Canny']('identify', {
        appID: '6752c9b08cdfb1e3680b2a47',
        user: {
          email: user.email,
          name: user.name,
          id: user.id,

          created: new Date(user.created_at).toISOString(),
        },
      });
    });

    window['Canny']('render', {
      boardToken: BoardToken,
      basePath: null,
      ssoToken: null,
      theme: 'light', // options: light [default], dark, auto
      onLoadCallback: () => {
        this.isLoaded = true;
      },
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
