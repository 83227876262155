import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-connection-groups',
  template: `@if (isGroupShowView) {
      <connections-list [groupId]="groupId"></connections-list>
    } @else {
      <connection-groups-list></connection-groups-list>
    }`,
})
export class ConnectionGroupsComponent implements OnInit {
  groupId = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.groupId = this.route.snapshot?.firstChild?.params['group_id'];

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
      this.groupId = this.route.snapshot?.firstChild?.params['group_id'];
    });
  }

  get isGroupShowView(): boolean {
    return (
      !!this.router.url.match(/\/connections\/groups\/\d+$/) ||
      !!this.router.url.match(/\/connections\/groups\/\d+\/[a-zA-Z_-]+\/\d+\/edit$/) ||
      !!this.router.url.match(/\/connections\/groups\/\d+\/[a-zA-Z_-]+\/new$/) ||
      !!this.router.url.match(/\/connections\/groups\/\d+\/new$/)
    );
  }
}
