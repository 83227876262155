import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { AnyConnection, ConnectionGroup } from '../../connection.models';
import { NotifyService } from '../../../common/services/notify.service';
import { AppState } from '../../../store';
import { BaseForm, BaseFormInterface } from '../../../common/base/base-form.component';
import {
  closeConnectionsGroupModal,
  createConnectionGroup,
  updateConnectionGroup,
} from '../../store/connection-groups.actions';
import {
  selectConnectionGroupsErrors,
  selectConnectionGroupsLoading,
  selectConnectionsGroupItem,
} from '../../store/connection-groups.selectors';
import { ListParams } from '../../../common/helper/query-params-generic-list.helper';
import { AuthorizationGuard } from '../../../common/services/authorization.guard';
import { SelectPickerTypes } from '../../../common/components/forms/select-picker/select-picker-types.enum';
import { isEmpty } from 'lodash';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'connections-group-form',
  template: `
    <div class="connections-group-form">
      <div class="connections-group-form-body">
        <xp-form-validation [type]="item.id ? 'ConnectionGroup' : 'NewConnectionGroup'" [name]="formName">
          <form id="connectionsGroupForm" name="connectionsGroupForm" #form="ngForm" class="form modal-form-container">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <xp-name-description-editor
                    [item]="item"
                    [optionalName]="!item.id"
                    namePlaceholder="connection-group.form.new.placeholders.name"
                  ></xp-name-description-editor>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="packages-picker">
                  <xp-select-picker-editable
                    id="connection-picker-connections-group-form"
                    [value]="connectionSelectValue"
                    [type]="selectPickerTypes.connection"
                    [filteredItems]="currentlySelected"
                    [addItemToList]="addItemToList"
                    placeholder="Add connection"
                    [filterConnectionsWithConnectionGroup]="true"
                    [hideNew]="true"
                    (valueChange)="addConnection($event)"
                  ></xp-select-picker-editable>

                  <div class="packages-picker-list" *ngIf="!(isLoading$ | async) && (item.connections || []).length">
                    <xp-connection
                      class="connection"
                      *ngFor="let connectionItem of item.connections"
                      [connection]="connectionItem"
                      (remove)="removeConnection($event)"
                      [hideEdit]="true"
                      [hideVersion]="true"
                    ></xp-connection>
                  </div>
                  <xp-loader *ngIf="(isLoading$ | async) || isLoadingItem"></xp-loader>
                </div>
              </div>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="connections-group-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-connection-group" size="L"></common-icon>
          <h3 class="modal-title">
            {{
              (item.id ? 'connection-group.generic-object.title_update' : 'connection-group.generic-object.title')
                | translate
            }}
          </h3>
        </div>

        <xp-submit-button
          (click)="saveConnectionGroup(item)"
          classNames="btn-lg btn-success pull-right modal-btn-save"
          [createText]="!item.id && 'connection-group.form.buttons.create' | translate"
          [updateText]="item.id && 'connection-group.form.buttons.update' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isLoading$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class ConnectionsGroupFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Input() item: Partial<ConnectionGroup> = {};
  @Input() isLoadingItem: boolean;
  @Output() close = new EventEmitter<void>();
  @ViewChild('form') form: NgForm;

  formName = 'connectionsGroupForm';
  successMessageText = 'connection-group.form.success_message';
  isLoading$ = this.store.select(selectConnectionGroupsLoading);
  errors$ = this.store.select(selectConnectionGroupsErrors);
  errorTexts = [];
  selectPickerTypes = SelectPickerTypes;
  addItemToList: Partial<AnyConnection>;
  currentlySelected: number[] = [];
  connectionSelectValue: Partial<AnyConnection>;
  isConnectionGroupSaved = false;

  connectionGroupItemSubscriber: Subscription;
  connectionGroupItem$ = this.store.select(selectConnectionsGroupItem);

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authGuard: AuthorizationGuard,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.connectionGroupItemSubscriber = this.connectionGroupItem$
      .pipe(filter((item) => Boolean(item) && !isEmpty(item)))
      .subscribe((connectionGroupItem: ConnectionGroup) => {
        if (!this.isConnectionGroupSaved) {
          this.item = { ...connectionGroupItem };
          this.isConnectionGroupSaved = false;
        }
      });
  }

  saveConnectionGroup(group: Partial<ConnectionGroup>) {
    const newGroup: Partial<ConnectionGroup> = { ...group };
    const params: ListParams = {};

    if (!newGroup.name) {
      newGroup.name = 'Untitled connection group';
    }

    if (group.id) {
      params.name = group.name;
      if (group.description) {
        params.description = group.description;
      }
      this.store.dispatch(
        updateConnectionGroup({
          groupId: group.id,
          group: newGroup,
        }),
      );
    } else {
      let redirectUrl = null;
      const groupId = this.route.snapshot?.firstChild?.params['group_id'];

      if (!groupId) {
        const accountId = this.route?.parent?.snapshot?.params['account_id'];
        if (accountId) {
          redirectUrl = `/${accountId}/connections/groups`;
        }
      }

      this.store.dispatch(createConnectionGroup({ group: newGroup, redirectUrl }));
    }

    this.close.emit();
  }

  removeConnection(connectionItem: Partial<AnyConnection>) {
    this.item.connections = (this.item.connections as AnyConnection[]).filter((item) => item.id !== connectionItem.id);
    this.addItemToList = connectionItem;
    this.currentlySelected = this.item.connections.map((item) => item.id);
    this.connectionSelectValue = null;
  }

  addConnection(connectionItem: Partial<AnyConnection>) {
    this.item.connections = [...(this.item.connections || []), connectionItem] as AnyConnection[];
    this.currentlySelected = this.item.connections.map((item) => item.id);
    this.connectionSelectValue = null;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.connectionGroupItemSubscriber.unsubscribe();
  }
}
