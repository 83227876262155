import { cloneDeep } from 'lodash';
import {
  AuthMethod,
  ConnectionType,
  ConnectionTypeName,
  MongoConnectionScheme,
  MongoReadPreference,
  S3Connection,
  SalesforceEnvironment,
  SomeConnection,
} from '../connection.models';
import { OAuthName } from './connections-authentication.config';

export interface ConnectionTypeModel {
  short_name?: string;
  defaults?: Partial<SomeConnection>;
  auth?: OAuthName;
  name?: string;
  description?: string;
  type?: string;
  icon_url?: string;
  auth_data?: any;
  button?: {
    test?: boolean;
    create?: boolean;
    authenticate?: boolean;
  };
}

export interface Connections {
  [key: string]: ConnectionTypeModel;
}

export interface ConnectionTypesGroup {
  connections: ConnectionType[];
  name: string;
  type: string;
}

const sortByName = (a, b) => (a.name > b.name ? 1 : -1);

export const defaultConnections: Connections = {
  vertica: {
    defaults: {
      tunnel_type: 'direct',
      port: 5433,
    },
  },
  azuresynapseanalytics: {
    defaults: {
      tunnel_type: 'direct',
      port: 1433,
      ssl: true,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  db2: {
    defaults: {
      tunnel_type: 'direct',
      port: 50001,
      ssl: true,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  as400: {
    defaults: {
      tunnel_type: 'direct',
      port: 50001,
      ssl: true,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  intercom: {
    defaults: {},
    auth: OAuthName.intercom,
  },
  restapi: {},
  googlecloudpostgres: {
    short_name: 'Google Cloud Postgres',
    defaults: {
      tunnel_type: 'direct',
      port: 5432,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  alloy: {
    defaults: {
      tunnel_type: 'direct',
      port: 5432,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  googlecloudspanner: {
    auth: OAuthName.googleCloudSpanner,
    defaults: {
      data_boost: false,
    },
  },
  adwords: {
    defaults: {},
    auth: OAuthName.adwords,
  },
  twitter: {
    auth: OAuthName.adwords,
  },
  analytics: {
    defaults: {},
    auth: OAuthName.analytics,
  },
  ga4: {
    defaults: {},
  },
  bigquery: {
    defaults: {
      region: 'gcloud::us-central1',
    },
  },
  bigqueryv2: {
    defaults: {
      region: 'gcloud::us-central1',
    },
  },
  bingads: {
    defaults: {},
    auth: OAuthName.bingAds,
  },
  googlecloudsql: {
    short_name: 'Google Cloud MySQL',
    defaults: {
      tunnel_type: 'direct',
      port: 3306,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  gs: { defaults: {} },
  gsv2: { defaults: {} },
  hana: { defaults: {} },
  hdfs: { short_name: 'HDFS', defaults: {} },
  herokupostgres: {
    defaults: {
      tunnel_type: 'direct',
      port: 5432,
      ssl: true,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  facebookadsinsights: {
    defaults: {},
    auth: OAuthName.facebookAdsInsights,
  },
  facebookads: {
    defaults: {},
    auth: OAuthName.facebookAds,
  },
  mongo: {
    defaults: {
      tunnel_type: 'direct',
      port: 27017,
      ssl: false,
      read_preference: MongoReadPreference.primary,
      ssh_port: 22,
      ssh_username: 'xplenty',
      connection_scheme: MongoConnectionScheme.dnsSeedList,
    },
  },
  mysql: {
    defaults: {
      tunnel_type: 'direct',
      port: 3306,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  postgres: {
    defaults: {
      tunnel_type: 'direct',
      port: 5432,
      ssl: true,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  oracle: {
    defaults: {
      tunnel_type: 'direct',
      port: 1521,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  oracleadw: {
    defaults: {
      tunnel_type: 'direct',
      port: 1521,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  rackspace: { defaults: {} },
  redshift: {
    defaults: {
      tunnel_type: 'direct',
      port: 5439,
      ssl: true,
      region: 'amazon-web-services::us-east-1',
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  s3: {
    defaults: {
      region: 'amazon-web-services::us-east-1',
    } as Partial<S3Connection>,
  },
  salesforce: {
    auth: OAuthName.salesforce,
    defaults: {
      environment: SalesforceEnvironment.production,
      auth_method: AuthMethod.salesforce,
    },
  },
  salesforcesoap: {
    defaults: {},
  },
  sftp: {
    short_name: 'SFTP',
    defaults: {
      auth_method: AuthMethod.password,
      tunnel_type: 'direct',
      port: 22,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  ftps: {
    short_name: 'FTPS',
    defaults: {
      tunnel_type: 'direct',
      port: 21,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  softlayer: { defaults: {} },
  sqlserver: {
    defaults: {
      tunnel_type: 'direct',
      port: 1433,
      ssl: true,
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  swift: {
    defaults: {
      auth_method: AuthMethod.swauth,
    },
  },
  netsuite: {
    defaults: {
      database: 'NetSuite.com',
      port: 1708,
      role_id: 0,
    },
  },
  azureblobstorage: { defaults: {} },
  pipedrive: {
    defaults: {},
    auth: OAuthName.pipedrive,
  },
  snowflake: {
    defaults: {
      tunnel_type: 'direct',
      region: 'snowflake::us-west-2',
      ssh_port: 22,
      ssh_username: 'xplenty',
    },
  },
  xero: {
    auth: OAuthName.xero,
    defaults: {},
  },
  linkedin: {
    auth: OAuthName.linkedin,
    defaults: {},
  },
  instagram: {
    auth: OAuthName.instagram,
    defaults: {},
  },
  googlesheets: {
    auth: OAuthName.googleSheets,
    defaults: {},
  },
  googledrive: {
    auth: OAuthName.googleDrive,
    defaults: {},
  },
  youtube: {
    auth: OAuthName.youtube,
    defaults: {},
  },
  shopify: {
    auth: OAuthName.shopify,
    defaults: {
      auth_method: AuthMethod.shopify,
    },
  },
  tiktokads: {
    auth: OAuthName.tiktokAds,
    defaults: {},
  },
  athena: {
    defaults: {
      region: 'amazon-web-services::us-east-1',
    },
  },
  hubspot: { defaults: {} },
  hubspotoauth: { auth: OAuthName.hubspotOAuth, defaults: {} },
  curl: { defaults: {} },
  marketingcloud: {
    defaults: {},
  },
  marketingcloudrest: {
    defaults: {},
  },
  netsuitesoap: {
    short_name: 'Netsuite SOAP',
    defaults: {},
  },
};

export function extendConnections(connectionTypes: ConnectionType[]): Connections {
  const newConnections = cloneDeep(defaultConnections);

  connectionTypes.forEach((connectionType) => {
    if (!newConnections[connectionType.type]) {
      return;
    }
    newConnections[connectionType.type].name = connectionType.name;
    newConnections[connectionType.type].description = connectionType.description;
    newConnections[connectionType.type].type = connectionType.type;
    newConnections[connectionType.type].icon_url = connectionType.icon_url;
    newConnections[connectionType.type].auth_data = {};
    newConnections[connectionType.type].button = {
      test: false,
      create: false,
      authenticate: false,
    };
  });

  return newConnections;
}

export const FILTERED_CONNECTION_TYPES = [
  ConnectionTypeName.bigQuery,
  ConnectionTypeName.gs,
  ConnectionTypeName.hubspot,
];

export function fillGroups(connectionTypes: ConnectionType[]): ConnectionTypesGroup[] {
  const groups: { [key: string]: ConnectionTypesGroup } = {};
  const groupsArray: ConnectionTypesGroup[] = [];
  connectionTypes
    .filter((connectionType) => !FILTERED_CONNECTION_TYPES.includes(connectionType.type))
    .forEach((connectionType) => {
      connectionType.groups.forEach((group) => {
        if (groups[group.group_type]) {
          groups[group.group_type].connections.push(connectionType);
        } else {
          groups[group.group_type] = {
            name: group.group_name,
            connections: [connectionType],
            type: group.group_type,
          };
        }
      });
    });

  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const groupId in groups) {
    groupsArray.push(groups[groupId]);
  }

  return groupsArray.map((group) => ({ ...group, connections: group.connections.sort(sortByName) })).sort(sortByName);
}

export function hasTableSchemaEndpointsHelper(connectionType: ConnectionTypeName): boolean {
  return [
    ConnectionTypeName.mysql,
    ConnectionTypeName.postgres,
    ConnectionTypeName.herokuPostgres,
    ConnectionTypeName.sqlserver,
    ConnectionTypeName.oracle,
    ConnectionTypeName.snowflake,
    ConnectionTypeName.db2,
    ConnectionTypeName.redshift,
  ].includes(connectionType);
}
