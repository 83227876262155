import { Component, EventEmitter, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { Package } from '../../packages/package.models';
import { PermissionsService } from '../services/permissions.service';
import { AuthorizationGuard } from '../services/authorization.guard';
import { AnyConnection } from '../../connections/connection.models';
import { connectionIconUrlByType } from '../helper/connection-icon-url-by-type.helper';

@Component({
  selector: 'xp-connection',
  template: `
    <div class="connection-header">
      <div class="connection-header-icon">
        <img class="icon" [src]="getConnectionIcon(connection.type)" [alt]="connection.type" />
      </div>
      <div class="connection-header-info">
        <div class="package-header-name">
          {{ connection.name | xpLengthCheck: 50 }}
        </div>
      </div>
      <div class="connection-header-buttons">
        <button type="button" class="btn btn-default btn-sm" (click)="removeConnection(connection)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>
  `,
})
export class XpConnectionComponent {
  @Input() connection: Partial<AnyConnection>;
  @Input() hideEdit: boolean;
  @Input() hideVersion: boolean;
  @Output() remove = new EventEmitter<Partial<AnyConnection>>();
  @Output() edit = new EventEmitter<Partial<AnyConnection>>();
  getConnectionIcon = connectionIconUrlByType;

  constructor() {}

  removeConnection(connectionItem: Partial<AnyConnection>) {
    this.remove.emit(connectionItem);
  }

  editConnection(connectionItem: Partial<AnyConnection>) {
    this.edit.emit(connectionItem);
  }
}
