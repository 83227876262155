import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState } from '../../store';
import { selectAccountId } from '../../account/store/account.selectors';
import { ConnectionGroup } from '../connection.models';
import { QueryParams } from '../../models/http.models';
import { environment } from '../../../environments/environment';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';

@Injectable({
  providedIn: 'root',
})
export class ConnectionGroupsResource {
  private apiRoot: string = `${environment.API_URL}`;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  get(connection_id: number, params?: ListParams): Observable<ConnectionGroup> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<ConnectionGroup>(`${this.apiRoot}/${account_id}/api/connection_groups/${connection_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  save(body: Partial<ConnectionGroup>, params?: ListParams): Observable<ConnectionGroup> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.post<ConnectionGroup>(`${this.apiRoot}/${account_id}/api/connection_groups`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  update(connection_id: number, body: Partial<ConnectionGroup>, params?: ListParams): Observable<ConnectionGroup> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.put<ConnectionGroup>(`${this.apiRoot}/${account_id}/api/connection_groups/${connection_id}`, body, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  remove(connection_id: number, params?: ListParams): Observable<any> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.delete<ConnectionGroup>(`${this.apiRoot}/${account_id}/api/connection_groups/${connection_id}`, {
          params: params as QueryParams,
        }),
      ),
    );
  }

  query(params?: ListParams): Observable<ConnectionGroup[]> {
    return this.store.pipe(
      selectAccountId,
      mergeMap((account_id) =>
        this.http.get<ConnectionGroup[]>(`${this.apiRoot}/${account_id}/api/connection_groups`, {
          params: params as QueryParams,
        }),
      ),
    );
  }
}
