import { createAction, props } from '@ngrx/store';
import { ValidationError } from '../../config/validation-error.model';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';
import { ConnectionGroup } from '../connection.models';

export const getConnectionGroupItem = createAction(
  '[Connection Groups] Get connection group item',
  props<{ groupId: number }>(),
);

export const getConnectionGroupItemResponse = createAction(
  '[Connection Groups] Get connection group item response',
  props<{ group: ConnectionGroup }>(),
);

export const getConnectionGroupItemError = createAction(
  '[Connection Groups] Get connection group item error',
  props<{ errors: ValidationError[] }>(),
);

export const getConnectionGroupsList = createAction(
  '[Connection Groups] Get connection groups list',
  props<{ params: ListParams }>(),
);

export const getConnectionGroupsListResponse = createAction(
  '[Connection Groups] Get connection groups list response',
  props<{ groups: ConnectionGroup[] }>(),
);

export const getConnectionGroupsListError = createAction(
  '[Connection Groups] Get connection groups list error',
  props<{ errors: ValidationError[] }>(),
);

export const loadMoreConnectionGroupsList = createAction(
  '[Connection Groups] Load more connection groups list',
  props<{ params: ListParams }>(),
);

export const loadMoreConnectionGroupsListResponse = createAction(
  '[Connection Groups] Load more connection groups list response',
  props<{ groups: ConnectionGroup[] }>(),
);

export const loadMoreConnectionGroupsListError = createAction(
  '[Connection Groups] Load more connection groups list error',
  props<{ errors: ValidationError[] }>(),
);

export const clearConnectionGroupItem = createAction('[Connection Groups] Clear connection group item');

export const createConnectionGroup = createAction(
  '[Connection Groups] Create connection group',
  props<{ group: Partial<ConnectionGroup>; redirectUrl?: string }>(),
);

export const createConnectionGroupResponse = createAction(
  '[Connection Groups] Create connection  group response',
  props<{ group: ConnectionGroup }>(),
);

export const createConnectionGroupError = createAction(
  '[Connection Groups] Create connection group error',
  props<{ errors: ValidationError[] }>(),
);

export const updateConnectionGroup = createAction(
  '[Connection Groups] Update connection group',
  props<{ groupId: number; group: Partial<ConnectionGroup> }>(),
);

export const updateConnectionGroupResponse = createAction(
  '[Connection Groups] Update connection group response',
  props<{ group: ConnectionGroup }>(),
);

export const updateConnectionGroupError = createAction(
  '[Connection Groups] Update connection group error',
  props<{ errors: ValidationError[] }>(),
);

export const deleteConnectionGroup = createAction(
  '[Connection Groups] Delete connection group',
  props<{ groupId: number }>(),
);

export const deleteConnectionGroupResponse = createAction(
  '[Connection Groups] Delete connection group response',
  props<{ groupId: number }>(),
);

export const deleteConnectionGroupError = createAction(
  '[Connection Groups] Delete connection group error',
  props<{ errors: ValidationError[] }>(),
);

export const openConnectionsGroupModal = createAction('[Connection Groups] Open connections group modal');

export const closeConnectionsGroupModal = createAction('[Connection Groups] Close connections group modal');
