import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConnectionGroupsState } from './connection-groups.reducer';

export const selectConnectionGroupsState = createFeatureSelector<ConnectionGroupsState>('connectionGroups');

export const selectConnectionGroups = createSelector(selectConnectionGroupsState, (state) => state.groups);

export const selectConnectionGroupsLoading = createSelector(selectConnectionGroupsState, (state) => state.loading);

export const selectConnectionGroupsLoaded = createSelector(
  selectConnectionGroupsState,
  (state) => state.areAllItemsLoaded,
);

export const selectConnectionGroupsErrors = createSelector(selectConnectionGroupsState, (state) => state.errors);

export const selectConnectionGroupById = (groupId: number) =>
  createSelector(selectConnectionGroups, (groups) => groups.find((group) => group.id === groupId));

export const selectConnectionsGroupModalOpen = createSelector(
  selectConnectionGroupsState,
  (state) => state.isModalOpen,
);

export const selectConnectionsGroupItem = createSelector(selectConnectionGroupsState, (state) => state.item);
