import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import CodeMirror from 'codemirror';
import 'codemirror/addon/display/placeholder';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/mode/sql/sql';
import 'codemirror/addon/dialog/dialog';
import 'codemirror/addon/tern/tern';
import { ConditionOperatorHandler } from '../../../constants/conditions_operator_picker';
import { openExpressionEditor } from '../../store/package-designer.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { selectExpressionEditorSaveCode, selectIsExpressionOpenFlag } from '../../store/package-designer.selectors';
import { filter, withLatestFrom } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CodeEditorDialogComponent } from './code-editor-dialog.component';

@Component({
  selector: 'code-editor',
  template: `
    <div class="code-editor">
      <div class="editor-button editor-button-open" (click)="editorButtonOpen()" *ngIf="hasExpressionEditorButton">
        <xp-icon type="edit"></xp-icon>
      </div>
      <button class="btn btn-primary open-code-editor-dialog" (click)="openCodeEditorDialog()">Open Code Editor</button>
      <textarea class="form-control" id="code-editor" [ngModel]="value" [attr.name]="name"></textarea>
    </div>
  `,
})
export class CodeEditorComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() value: string;
  @Input() name: string;
  @Input() options: any;
  @Input() hasExpressionEditorButton = false;
  @Input() tables: string[] = [];
  @Input() editorTitle: string;
  @Output() valueChange = new EventEmitter<string>();

  textAreaElement: HTMLTextAreaElement;
  editor = null;
  isExpressionEditorOpen = false;
  expressionEditorCloseSubscription: Subscription;
  editorOptions: any = {};

  constructor(
    private elementRef: ElementRef,
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    this.textAreaElement = this.elementRef.nativeElement.querySelector('textarea');

    this.initEditor();

    this.expressionEditorCloseSubscription = this.store
      .select(selectIsExpressionOpenFlag)
      .pipe(
        filter((isOpen) => !isOpen),
        withLatestFrom(this.store.select(selectExpressionEditorSaveCode)),
      )
      .subscribe(([, code]) => {
        if (this.isExpressionEditorOpen) {
          if (code) {
            this.editor.setValue(code);
            this.valueChange.emit(code);
          }
          this.isExpressionEditorOpen = false;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.value?.currentValue !== changes.value?.previousValue &&
      !changes.value?.firstChange &&
      this.editor &&
      this.value !== this.editor.getValue()
    ) {
      this.editor.setValue(this.value);
    }

    if (changes.tables?.currentValue && this.editor) {
      const currentHintOptions = this.editor.getOption('hintOptions') || {};

      this.editor.setOption('hintOptions', {
        ...currentHintOptions,
        tables: {
          ...(currentHintOptions.tables || {}),
          ...this.tables.reduce((acc, table) => {
            acc[table] = [];
            return acc;
          }, {}),
        },
      });

      this.editorOptions = {
        ...this.editorOptions,
        hintOptions: {
          ...this.editorOptions.hintOptions,
          tables: {
            ...this.editorOptions.hintOptions.tables,
          },
        },
      };
    }
  }

  initEditor() {
    const oldCodeMirror = document.querySelector('.CodeMirror');

    if (oldCodeMirror) {
      oldCodeMirror.parentElement.removeChild(oldCodeMirror);
    }

    setTimeout(() => {
      const defaultOptions = {
        lineWrapping: true,
        lineNumbers: true,
        mode: 'text/x-sql',
        placeholder: 'SELECT * FROM table',
        extraKeys: {
          'Ctrl-Space': 'autocomplete',
        },
        hintOptions: {
          tables: {
            ...(this.tables || []).reduce((acc, table) => {
              acc[table] = [];
              return acc;
            }, {}),
          },
          completeSingle: false,
          completeOnSingleClick: true,
        },
      };

      const options = { ...defaultOptions, ...(this.options || {}) };

      this.editorOptions = options;

      this.editor = CodeMirror.fromTextArea(this.textAreaElement, options);

      if (this.value) this.editor.setValue(this.value);

      // Enable auto hints
      this.editor.on('keyup', (cm: CodeMirror.Editor, event: KeyboardEvent) => {
        if (
          !cm.state.completionActive &&
          !event.ctrlKey &&
          !event.altKey &&
          !event.metaKey &&
          /^[a-zA-Z.]$/.test(event.key)
        ) {
          CodeMirror.commands.autocomplete(cm, null, { completeSingle: false });
        }
      });

      this.editor.on('change', (cm) => {
        this.value = cm.getValue();

        this.valueChange.emit(this.value);

        this.textAreaElement.dispatchEvent(
          new Event('input', {
            bubbles: true,
            cancelable: true,
          }),
        );
      });

      this.editor.on('blur', (cm) => {
        this.value = cm.getValue();
        this.valueChange.emit(this.value);
      });
    }, 0);
  }

  public openCodeEditorDialog() {
    const dialogRef = this.dialog.open(CodeEditorDialogComponent, {
      data: {
        value: this.value,
        tables: this.tables,
        options: this.editorOptions,
        name: this.name,
        cursorPosition: this.editor?.getCursor(),
        title: this.editorTitle || 'SQL Editor',
      },
      position: {
        top: '50px',
      },
      maxWidth: 'calc(100vw - 200px)',
      maxHeight: 'calc(100vh - 200px)',
      width: '100%',
      height: '100%',
      panelClass: 'code-editor-dialog',
    });

    dialogRef.afterClosed().subscribe(async (data) => {
      if (!data) return;

      const { value, cursorPosition } = data;

      if (value) {
        this.value = value;
        this.valueChange.emit(this.value);
      }
      this.editor.focus();

      if (cursorPosition) {
        this.editor.setCursor(cursorPosition);
      }
    });
  }

  editorButtonOpen() {
    this.openExpression();
  }

  openExpression() {
    this.isExpressionEditorOpen = true;
    this.store.dispatch(
      openExpressionEditor({
        code: this.value,
        origin: 'fields-collection',
        category: 'User',
      }),
    );
  }

  ngOnDestroy() {
    if (this.editor) {
      this.editor.toTextArea();
    }

    if (this.expressionEditorCloseSubscription) {
      this.expressionEditorCloseSubscription.unsubscribe();
    }
  }

  protected readonly ConditionOperatorHandler = ConditionOperatorHandler;
}
