import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ConnectionGroupsResource } from '../resources/connection-groups.resource';
import * as ConnectionGroupsActions from './connection-groups.actions';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from 'src/app/common/services/notify.service';
import { Router } from '@angular/router';

@Injectable()
export class ConnectionGroupsEffects {
  getConnectionGroupItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionGroupsActions.getConnectionGroupItem),
      mergeMap(({ groupId }) =>
        this.connectionGroupsResource.get(groupId).pipe(
          map((group) => ConnectionGroupsActions.getConnectionGroupItemResponse({ group })),
          catchError((error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
            return of(ConnectionGroupsActions.getConnectionGroupItemError({ errors: error.error.errors }));
          }),
        ),
      ),
    ),
  );

  getConnectionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionGroupsActions.getConnectionGroupsList),
      mergeMap(({ params }) =>
        this.connectionGroupsResource.query(params).pipe(
          map((groups) => ConnectionGroupsActions.getConnectionGroupsListResponse({ groups })),
          catchError((error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
            return of(
              ConnectionGroupsActions.getConnectionGroupsListError({
                errors: error.error.errors,
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadMoreConnectionGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionGroupsActions.loadMoreConnectionGroupsList),
      mergeMap(({ params }) =>
        this.connectionGroupsResource.query(params).pipe(
          map((groups) => ConnectionGroupsActions.loadMoreConnectionGroupsListResponse({ groups })),
          catchError((error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
            return of(ConnectionGroupsActions.loadMoreConnectionGroupsListError({ errors: error.error.errors }));
          }),
        ),
      ),
    ),
  );

  createConnectionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionGroupsActions.createConnectionGroup),
      mergeMap(({ group, redirectUrl }) =>
        this.connectionGroupsResource.save(group).pipe(
          map((createdGroup) => {
            if (redirectUrl) {
              this.router.navigate([redirectUrl, createdGroup.id]);
            }
            this.notify.success('Connections group created successfully.');
            return ConnectionGroupsActions.createConnectionGroupResponse({
              group: createdGroup,
            });
          }),
          catchError((error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
            return of(
              ConnectionGroupsActions.createConnectionGroupError({
                errors: error.error.errors,
              }),
            );
          }),
        ),
      ),
    ),
  );

  updateConnectionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionGroupsActions.updateConnectionGroup),
      mergeMap(({ groupId, group }) =>
        this.connectionGroupsResource.update(groupId, group).pipe(
          map((updatedGroup) => {
            this.notify.success('Connections group updated successfully.');
            return ConnectionGroupsActions.updateConnectionGroupResponse({
              group: updatedGroup,
            });
          }),
          catchError((error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
            return of(
              ConnectionGroupsActions.updateConnectionGroupError({
                errors: error.error.errors,
              }),
            );
          }),
        ),
      ),
    ),
  );

  deleteConnectionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionGroupsActions.deleteConnectionGroup),
      mergeMap(({ groupId }) =>
        this.connectionGroupsResource.remove(groupId).pipe(
          map(() => {
            this.notify.success('Connections group deleted successfully.');
            return ConnectionGroupsActions.deleteConnectionGroupResponse({
              groupId,
            });
          }),
          catchError((error) => {
            this.notify.error(this.translate.instant(`response.${error.status}.message`));
            return of(
              ConnectionGroupsActions.deleteConnectionGroupError({
                errors: error.error.errors,
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private connectionGroupsResource: ConnectionGroupsResource,
    private translate: TranslateService,
    private notify: NotifyService,
    private router: Router,
  ) {}
}
