import { createReducer, on } from '@ngrx/store';
import { ConnectionGroup } from '../connection.models';
import { ValidationError } from '../../config/validation-error.model';
import * as ConnectionGroupsActions from './connection-groups.actions';
import { QueryParamsConnectionGroupsList } from 'src/app/common/helper/query-params-generic-list.helper';
import { updateConnection } from './connections.actions';

export interface ConnectionGroupsState {
  groups: ConnectionGroup[];
  loading: boolean;
  errors: ValidationError[] | null;
  areAllItemsLoaded: boolean;
  isModalOpen: boolean;
  item: ConnectionGroup | null;
}

export const initialState: ConnectionGroupsState = {
  groups: [],
  loading: false,
  errors: null,
  areAllItemsLoaded: false,
  isModalOpen: false,
  item: null,
};

export const connectionGroupsReducer = createReducer(
  initialState,

  on(ConnectionGroupsActions.clearConnectionGroupItem, (state) => ({
    ...state,
    item: null,
  })),

  // Get Connection Groups
  on(ConnectionGroupsActions.getConnectionGroupsList, (state) => ({
    ...state,
    loading: true,
    errors: null,
  })),

  on(ConnectionGroupsActions.getConnectionGroupsListResponse, (state, { groups }) => ({
    ...state,
    groups,
    loading: false,
    errors: null,
    areAllItemsLoaded: groups.length < QueryParamsConnectionGroupsList.limit,
  })),

  on(ConnectionGroupsActions.getConnectionGroupsListError, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),

  // Load More Connection Groups
  on(ConnectionGroupsActions.loadMoreConnectionGroupsList, (state) => ({
    ...state,
    loading: true,
    errors: null,
  })),

  on(ConnectionGroupsActions.loadMoreConnectionGroupsListResponse, (state, { groups }) => ({
    ...state,
    groups: [...state.groups, ...groups],
    loading: false,
    errors: null,
    areAllItemsLoaded: groups.length < QueryParamsConnectionGroupsList.limit,
  })),

  on(ConnectionGroupsActions.loadMoreConnectionGroupsListError, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),

  // Create Connection Group
  on(ConnectionGroupsActions.createConnectionGroup, (state) => ({
    ...state,
    loading: true,
    errors: null,
  })),

  on(ConnectionGroupsActions.createConnectionGroupResponse, (state, { group }) => ({
    ...state,
    groups: [group, ...state.groups],
    loading: false,
    errors: null,
  })),

  on(ConnectionGroupsActions.createConnectionGroupError, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),

  // Update Connection Group
  on(ConnectionGroupsActions.updateConnectionGroup, (state) => ({
    ...state,
    loading: true,
    errors: null,
  })),

  on(ConnectionGroupsActions.updateConnectionGroupResponse, (state, { group }) => ({
    ...state,
    groups: state.groups.map((g) => (g.id === group.id ? group : g)),
    item: state.item?.id === group.id ? group : state.item,
    loading: false,
    errors: null,
  })),

  on(ConnectionGroupsActions.updateConnectionGroupError, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),

  // Delete Connection Group
  on(ConnectionGroupsActions.deleteConnectionGroup, (state) => ({
    ...state,
    loading: true,
    errors: null,
  })),

  on(ConnectionGroupsActions.deleteConnectionGroupResponse, (state, { groupId }) => ({
    ...state,
    groups: state.groups.filter((group) => group.id !== groupId),
    loading: false,
    errors: null,
  })),

  on(ConnectionGroupsActions.deleteConnectionGroupError, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),

  // Open/Close Connections Group Modal
  on(ConnectionGroupsActions.openConnectionsGroupModal, (state) => ({
    ...state,
    isModalOpen: true,
  })),

  on(ConnectionGroupsActions.closeConnectionsGroupModal, (state) => ({
    ...state,
    isModalOpen: false,
  })),

  // Get Connection Group Item
  on(ConnectionGroupsActions.getConnectionGroupItem, (state) => ({
    ...state,
    loading: true,
    errors: null,
    item: null,
  })),

  on(ConnectionGroupsActions.getConnectionGroupItemResponse, (state, { group }) => ({
    ...state,
    loading: false,
    errors: null,
    item: group,
  })),

  on(ConnectionGroupsActions.getConnectionGroupItemError, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
    item: null,
  })),
);
