import { AfterViewInit, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { GenericListType, LoadMoreQuery } from '../../common/components/lists/generic-list.component';
import { AppState } from '../../store';
import { SearchConfig } from '../../common/services/assisted-search/search-config.model';
import { ConnectionGroup } from '../connection.models';
import { QueryParamsConnectionGroupsList } from '../../common/helper/query-params-generic-list.helper';
import {
  closeConnectionsGroupModal,
  getConnectionGroupItem,
  getConnectionGroupsList,
  loadMoreConnectionGroupsList,
  openConnectionsGroupModal,
  clearConnectionGroupItem,
} from '../store/connection-groups.actions';
import {
  selectConnectionGroups,
  selectConnectionGroupsErrors,
  selectConnectionGroupsLoaded,
  selectConnectionGroupsLoading,
  selectConnectionsGroupModalOpen,
} from '../store/connection-groups.selectors';
import { ChangeStateService } from '../../common/services/change-state.service';
import { CustomMiddleClickEvent } from '../../common/helper/global-types.helper';

@Component({
  selector: 'connection-groups-list',
  template: `
    <generic-list
      [type]="type"
      [items]="connectionGroups$ | async"
      [isLoading]="connectionGroupsLoading$ | async"
      [hasNewButton]="true"
      (createClick)="openConnectionGroupCreateModal()"
      [newBtnText]="'connection-group.generic-object.buttons.new' | translate"
      [emptyTitle]="'connection-group.generic-object.empty.title' | translate"
      [emptyDescription]="'connection-group.generic-object.empty.description' | translate"
      (loadMore)="loadMoreConnections($event)"
      [limit]="queryParams.limit"
      [allItemsLoaded]="areAllConnectionGroupsLoaded$ | async"
      [searchPlaceholder]="'connection-group.generic-object.placeholders.search' | translate"
      [searchConfig]="searchConfig"
      [searchQueryFromUrl]="searchQueryFromUrl"
      [hasSearch]="false"
      [isError]="connectionGroupsError$ | async"
      [customListHeader]="true"
    >
      <ng-template templateName="listHeader" let-item>
        <div class="generic-list-header-title">
          <a
            class="nav-link"
            (click)="changeState('connections', $event)"
            (auxclick)="changeState('connections', { isMiddleClick: true })"
          >
            Connections
          </a>
          <a
            class="nav-link active"
            (click)="changeState('connections/groups', $event)"
            (auxclick)="changeState('connections/groups', { isMiddleClick: true })"
          >
            Groups
          </a>
        </div>
      </ng-template>
      <ng-template templateName="listItem" let-item>
        <connection-group-list-item [item]="item" (edit)="editConnectionGroup($event)"></connection-group-list-item>
      </ng-template>
      <ng-template templateName="headers">
        <div class="generic-list-headers connection-groups">
          <div class="name">
            <span (click)="changeOrder('name')">
              <span class="sorting">{{ 'generic-list.connections.name' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'name',
                  'fa-chevron-down': queryParams.direction === 'asc' && queryParams.sort === 'name',
                  'fa-chevron-up': queryParams.direction === 'desc' && queryParams.sort === 'name',
                }"
              ></i>
            </span>
          </div>

          <div class="type">
            <span (click)="changeOrder('description')">
              <span class="sorting">Description</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'description',
                  'fa-chevron-down': queryParams.direction === 'asc' && queryParams.sort === 'description',
                  'fa-chevron-up': queryParams.direction === 'desc' && queryParams.sort === 'description',
                }"
              ></i>
            </span>
          </div>

          <div class="connections-count">{{ 'generic-list.connections.connections_count' | translate }}</div>
          <div class="owner">{{ 'generic-list.connections.owner' | translate }}</div>
          <div class="date">
            <span (click)="changeOrder('updated')">
              <span class="sorting">{{ 'generic-list.connections.date' | translate }}</span>
              <i
                class="fa"
                [ngClass]="{
                  active: queryParams.sort === 'updated',
                  'fa-chevron-down': queryParams.direction === 'desc' && queryParams.sort === 'updated',
                  'fa-chevron-up': queryParams.direction === 'asc' && queryParams.sort === 'updated',
                }"
              ></i>
            </span>
          </div>
          <div class="dropdown-menu-header"></div>
        </div>
      </ng-template>
    </generic-list>
    <xp-modal
      [isOpen]="isConnectionsGroupModalOpen$ | async"
      [closeButtonText]="'connection-group.generic-object.buttons.close' | translate"
      [saveButtonText]="
        formItem.id
          ? 'connection-group.generic-object.buttons.update'
          : ('connection-group.generic-object.buttons.save' | translate)
      "
      [titleText]="
        formItem.id
          ? 'connection-group.generic-object.title_update'
          : ('connection-group.generic-object.title' | translate)
      "
      (close)="onConnectionsGroupModalClose()"
    >
      <ng-template>
        <connections-group-form
          [item]="formItem"
          [groupId]="groupId"
          (close)="onConnectionsGroupModalClose()"
        ></connections-group-form>
      </ng-template>
    </xp-modal>
  `,
})
export class ConnectionGroupsListComponent implements AfterViewInit {
  type = GenericListType.connectionGroups;
  connectionGroupsLoading$ = this.store.select(selectConnectionGroupsLoading);
  connectionGroupsError$ = this.store.select(selectConnectionGroupsErrors);
  connectionGroups$ = this.store.select(selectConnectionGroups);
  areAllConnectionGroupsLoaded$ = this.store.select(selectConnectionGroupsLoaded);
  queryParams = QueryParamsConnectionGroupsList;
  formItem: Partial<ConnectionGroup> = {};
  searchQueryFromUrl = '';
  searchConfig: SearchConfig = {};
  isConnectionsGroupModalOpen$ = this.store.select(selectConnectionsGroupModalOpen);

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private changeStateService: ChangeStateService,
  ) {
    this.store.dispatch(getConnectionGroupsList({ params: this.queryParams }));
  }

  ngAfterViewInit() {
    if (this.router.url.includes('/connections/groups/new') || this.router.url.match(/\/connections\/groups\/new/)) {
      this.openConnectionGroupCreateModal();
    }

    if (this.router.url.match(/\/connections\/groups\/\w+\/edit/)) {
      const { group_id } = this.route.firstChild?.snapshot.params;
      this.editConnectionGroup({ id: group_id });
    }
  }

  changeOrder(field: string) {
    let { direction } = this.queryParams;
    if (field === this.queryParams.sort) {
      direction = direction === 'asc' ? 'desc' : 'asc';
    }
    this.queryParams = { ...this.queryParams, sort: field, direction };
    this.store.dispatch(getConnectionGroupsList({ params: this.queryParams }));
  }

  editConnectionGroup(item: Partial<ConnectionGroup>) {
    this.store.dispatch(getConnectionGroupItem({ groupId: item.id }));
    this.formItem = { ...item };
    this.router.navigate([`${item.id}/edit`], { relativeTo: this.route });
    this.store.dispatch(openConnectionsGroupModal());
  }

  openConnectionGroupCreateModal(): void {
    this.store.dispatch(clearConnectionGroupItem());
    this.formItem = {};

    this.router.navigate(['new'], { relativeTo: this.route });

    this.store.dispatch(openConnectionsGroupModal());
  }

  onConnectionsGroupModalClose(): void {
    this.router.navigate(['./'], { relativeTo: this.route });
    this.store.dispatch(closeConnectionsGroupModal());
  }

  loadMoreConnections(query: LoadMoreQuery) {
    const params = {
      ...this.queryParams,
      ...query,
    };
    this.store.dispatch(loadMoreConnectionGroupsList({ params }));
  }

  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    this.changeStateService.changeState(stateName, event);
  }
}
