import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { Package } from '../package.models';
import { NotifyService } from '../../common/services/notify.service';
import { AppState } from '../../store';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { closePackageWorkspacesModal, updatePackage } from '../store/packages.actions';
import { selectPackagesErrors, selectPackagesIsSubmittingFlag } from '../store/packages.selectors';
import { SelectPickerTypes } from '../../common/components/forms/select-picker/select-picker-types.enum';
import { Workspace } from '../../workspaces/workspaces.models';

@Component({
  selector: 'package-workspace-form',
  template: `
    <div class="package-workspace-editor">
      <div class="package-workspace-editor-body">
        <xp-form-validation [type]="'Package'" [name]="formName">
          <form name="packageVersionForm" class="form modal-form-container" #form="ngForm">
            <label for="workspace">{{ 'package.controller.modals.change_workspace.actions.title' | translate }}</label>
            <xp-select-picker-editable
              id="workspace-picker-package-form"
              [type]="selectPickerTypes.workspace"
              [value]="item.workspace"
              [hideNew]="true"
              placeholder="Select workspace"
              [filterPackagesWithWorkspace]="true"
              (valueChange)="onWorkspaceChange($event)"
            ></xp-select-picker-editable>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="package-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-workspace" size="L"></common-icon>
          <h3 class="modal-title">{{ 'package.controller.modals.change_workspace.actions.title' | translate }}</h3>
        </div>

        <xp-submit-button
          (click)="savePackage(item)"
          classNames="btn-lg btn-success pull-right rollback"
          [updateText]="'package.controller.modals.change_workspace.actions.save' | translate"
          [isFormValid]="workspaceChanged"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class PackageWorkspaceFormComponent extends BaseForm implements OnInit, BaseFormInterface {
  @Input() item: Partial<Package> = {};
  @ViewChild('form') form: NgForm;
  formName = 'packageVersionForm';
  successMessageText = 'package.form.success_message';
  isSubmitting$ = this.store.select(selectPackagesIsSubmittingFlag);
  errors$ = this.store.select(selectPackagesErrors);
  errorTexts = [];
  selectPickerTypes = SelectPickerTypes;
  workspaceChanged = false;
  prevWorkspaceId: number;

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.prevWorkspaceId = this.item.workspace_id;
  }

  onWorkspaceChange(workspace: Workspace) {
    this.item.workspace = workspace;
    this.item.workspace_id = workspace.id;
    this.workspaceChanged = Number(this.item.workspace_id) !== Number(this.prevWorkspaceId);
  }

  savePackage(packageItem: Partial<Package>) {
    this.store.dispatch(
      updatePackage({
        packageId: packageItem.id,
        packageItem: { workspace_id: Number(packageItem.workspace_id) === -1 ? null : packageItem.workspace_id },
        params: {},
      }),
    );

    this.store.dispatch(closePackageWorkspacesModal());

    this.router.navigate(['./'], { relativeTo: this.route });
  }
}
