import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import * as cronstrue from 'cronstrue';
import moment from 'moment';
import 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { Schedule } from '../schedule.models';
import { NotifyService } from '../../common/services/notify.service';
import { AppState } from '../../store';
import { BaseForm, BaseFormInterface } from '../../common/base/base-form.component';
import { saveSchedule, updateSchedule } from '../store/schedules.actions';
import { selectSchedulesErrors, selectSchedulesIsSubmittingFlag } from '../store/schedules.selectors';
import { SelectPickerTypes } from '../../common/components/forms/select-picker/select-picker-types.enum';
import { getStep } from '../../common/helper/get-step.helper';
import { validateCronValues } from '../helpers/cron.helpers';
import { Tag } from '../../common/components/xp-steps.component';
import { IdleTimesService, IdleTimeValues } from '../../clusters/services/idle-times.service';
import { Package } from '../../packages/package.models';
import { ListParams } from '../../common/helper/query-params-generic-list.helper';
import { ClusterTypes } from '../../clusters/clusters.models';
import { REST_API_RESPONSE_TYPES } from '../../constants/component_types';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { DialogType } from '../../common/services/components/dialog-template.component';
import { SelectOption } from '../../common/components/forms/xp-select.component';
import { TimezonesResource } from '../../settings/resources/timezones.resource';

function getTag(label: string, value: string): Tag {
  return { name: `<span>${label}</span>${label.length ? ':' : ''} <b>${value}</b>` };
}

@Component({
  selector: 'schedule-form',
  template: `
    <div class="schedule-form">
      <xp-loader *ngIf="isLoadingItem"></xp-loader>
      <div class="schedule-form-body">
        <xp-form-validation [type]="item.id ? 'Schedule' : 'NewSchedule'" [name]="formName">
          <form id="scheduleForm" name="scheduleForm" #form="ngForm" class="form">
            <div class="schedule-form-steps" *ngIf="!isLoadingItem">
              <div class="row">
                <div class="col-md-9">
                  <xp-name-description-editor
                    [item]="item"
                    [optionalName]="!item.id"
                    [namePlaceholder]="item.id ? '' : 'schedule.form.placeholders.name'"
                    [disableValidation]="true"
                  ></xp-name-description-editor>
                </div>
                <div class="col-md-3">
                  <div class="form-group status-form-group">
                    <label>{{ 'schedule.form.labels.status' | translate }}</label>
                    <div class="btn-group btn-group-md btn-group-select schedule-form-toggle">
                      <button
                        type="button"
                        class="btn btn-default"
                        [ngClass]="{ active: item.status === 'enabled' }"
                        value="raw"
                        (click)="toggleStatusChange()"
                      >
                        Active
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        [ngClass]="{ active: item.status === 'disabled' }"
                        value="json"
                        (click)="toggleStatusChange()"
                      >
                        Inactive
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <xp-steps>
                <xp-step [step]="formStep">
                  <div class="schedule-form-content">
                    <div class="form-group-options">
                      <div class="row-item">
                        <div class="label-item">
                          <div class="radio">
                            <input
                              type="radio"
                              [(ngModel)]="item.schedule_type"
                              name="operator"
                              id="operator-interval"
                              value="interval"
                              (click)="resetCronValidity(true); setFormStepTags()"
                            />
                            <label for="operator-interval">{{ 'schedule.form.labels.repeat' | translate }}</label>
                          </div>
                        </div>
                        <div class="inputs-item">
                          <div class="days-picker">
                            <div class="input-group">
                              <input
                                type="number"
                                class="form-control"
                                name="interval_amount"
                                id="interval_amount"
                                [(ngModel)]="item.interval_amount"
                                [disabled]="item.schedule_type === 'cron'"
                                (ngModelChange)="setFormStepTags()"
                              />
                              <button
                                type="button"
                                class="btn btn-default dropdown-toggle btn-gray"
                                [matMenuTriggerFor]="dropdownUnits"
                                [disabled]="item.schedule_type === 'cron'"
                              >
                                {{ currentUnit.name }} <span class="caret"></span>
                              </button>
                              <mat-menu #dropdownUnits="matMenu">
                                <li
                                  mat-menu-item
                                  *ngFor="let unit of units"
                                  (click)="selectUnit(unit.value); setFormStepTags()"
                                >
                                  {{ unit.name }}
                                </li>
                              </mat-menu>
                            </div>
                          </div>
                          <div class="time-picker">
                            <span class="time-picker-label">{{ 'schedule.form.labels.at' | translate }}</span>
                            <div class="input-group" id="datetimepicker3">
                              <input
                                id="datetimepicker3-input"
                                [ngModel]="start_hour_value"
                                (ngModelChange)="onTimeChange($event)"
                                type="text"
                                class="form-control"
                                name="start-hour"
                                #timepickerInput
                                [disabled]="item.schedule_type === 'cron'"
                                (input)="setStartAt()"
                              />
                              <button
                                class="btn btn-default btn-gray"
                                [ngClass]="{ disabled: item.schedule_type === 'cron' }"
                                [matMenuTriggerFor]="timepicker"
                              >
                                <span class="fa fa-clock-o"></span>
                              </button>
                              <mat-menu #timepicker="matMenu">
                                <xp-time-picker
                                  (click)="$event.stopPropagation()"
                                  (keydown)="$event.stopPropagation()"
                                  [value]="start_hour_value"
                                  (timeChange)="onTimeChange($event)"
                                >
                                </xp-time-picker>
                              </mat-menu>
                            </div>
                            <span class="time-picker-label timezone-label">in</span>
                            <xp-select
                              class="form-control xp-select"
                              [ngClass]="{ disabled: item.schedule_type === 'cron' }"
                              [value]="item.current_time_zone"
                              (valueChange)="setTimezone($event)"
                              [options]="timezones"
                              panelClass="timezone-picker-panel"
                              name="current_time_zone"
                              id="current_time_zone"
                              [isSearchEnabled]="true"
                              [showAll]="true"
                              [disabled]="item.schedule_type === 'cron'"
                            ></xp-select>
                            <span class="time-picker-label timezone-label">time zone</span>
                          </div>
                        </div>
                      </div>
                      <div class="row-item">
                        <div class="label-item">
                          <div class="radio">
                            <input
                              type="radio"
                              [(ngModel)]="item.schedule_type"
                              name="operator"
                              id="operator-cron"
                              value="cron"
                              (click)="validateCron(); setFormStepTags()"
                            />
                            <label for="operator-cron">{{ 'schedule.form.labels.cron' | translate }}</label>
                          </div>
                        </div>
                        <div class="inputs-item">
                          <div class="input-group cron-expression">
                            <input
                              type="text"
                              class="form-control"
                              [(ngModel)]="item.schedule_expression"
                              placeholder="0 8 * * MON"
                              (input)="setFrequencyMessage(); validateCron(); setFormStepTags()"
                              name="cron"
                              id="cron"
                              [disabled]="item.schedule_type === 'interval'"
                            />
                            <button
                              type="button"
                              class="btn btn-default dropdown-toggle btn-gray"
                              [matMenuTriggerFor]="dropdownPresets"
                              [disabled]="item.schedule_type === 'interval'"
                            >
                              <i class="fa fa-clock-o"></i>
                            </button>
                            <mat-menu #dropdownPresets="matMenu">
                              <li
                                mat-menu-item
                                *ngFor="let preset of presets"
                                (click)="selectPreset(preset.value); setFrequencyMessage(); setFormStepTags()"
                              >
                                {{ preset.name }}
                              </li>
                            </mat-menu>
                          </div>
                          <p [ngClass]="{ 'valid-cron': item.cron_validity, 'invalid-cron': !item.cron_validity }">
                            {{ item.schedule_message }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <mat-slide-toggle [(ngModel)]="item.overlap" (ngModelChange)="setFormStepTags()" name="overlap">
                        {{ 'schedule.form.checkbox' | translate }}</mat-slide-toggle
                      >
                    </div>
                  </div>
                </xp-step>
                <xp-step [step]="clusterStep">
                  <cluster-form
                    [item]="item.task || {}"
                    [productionOnly]="true"
                    [showReUse]="true"
                    [hideName]="true"
                    [hideFooter]="true"
                    (clusterChange)="setClusterStepTags()"
                  ></cluster-form>
                </xp-step>
                <xp-step [step]="packagesStep">
                  <package-picker
                    [packages]="item.task.packages"
                    (packagesChange)="updatePackages($event)"
                  ></package-picker>
                </xp-step>
              </xp-steps>
            </div>
          </form>
          <errors-notify [errors]="errorTexts"></errors-notify>
        </xp-form-validation>
      </div>
      <div class="schedule-form-footer modal-footer">
        <div class="modal-title-container active">
          <common-icon iconId="icon-schedules" size="L"></common-icon>
          <h3 class="modal-title">
            <span *ngIf="!item.id">{{ 'schedule.form.titles.new' | translate }}</span>
            <span *ngIf="item.id">{{ 'schedule.form.titles.edit' | translate }}</span>
          </h3>
        </div>

        <xp-submit-button
          (click)="saveScheduleClick(item)"
          classNames="btn-lg btn-success pull-right modal-btn-save"
          [createText]="!item.id && 'schedule.form.buttons.create' | translate"
          [updateText]="item.id && 'schedule.form.buttons.update' | translate"
          [isFormValid]="form.valid"
          [isFormSubmitting]="isSubmitting$ | async"
        ></xp-submit-button>
      </div>
    </div>
  `,
})
export class ScheduleFormComponent extends BaseForm implements OnInit, BaseFormInterface, OnChanges, AfterViewInit {
  @Input() item: Partial<Schedule> = {};
  @Input() isLoadingItem: boolean;
  @ViewChild('form') form: NgForm;
  @ViewChild('timepickerInput') timepickerInput: ElementRef<HTMLInputElement>;
  formName = 'scheduleForm';
  successMessageText = 'schedule.form.success_message';
  isSubmitting$ = this.store.select(selectSchedulesIsSubmittingFlag);
  errors$ = this.store.select(selectSchedulesErrors);
  errorTexts = [];
  selectPickerTypes = SelectPickerTypes;
  formStep = getStep({ title: 'Schedule', activeTitle: 'Configure schedule', valid: true, active: true });
  clusterStep = getStep({ title: 'Cluster', activeTitle: 'Configure schedule cluster', valid: true });
  packagesStep = getStep({ title: 'Packages', activeTitle: 'Select packages to run' });
  currentUnit;
  start_hour_value: string = '';

  units = [
    {
      name: this.translate.instant('units.minutes'),
      value: 'minutes',
    },
    {
      name: this.translate.instant('units.hours'),
      value: 'hours',
    },
    {
      name: this.translate.instant('units.days'),
      value: 'days',
    },
    {
      name: this.translate.instant('units.weeks'),
      value: 'weeks',
    },
    {
      name: this.translate.instant('units.months'),
      value: 'months',
    },
  ];

  presets = [
    {
      name: this.translate.instant('presets.daily'),
      value: '0 8 * * *',
    },
    {
      name: this.translate.instant('presets.weekly'),
      value: '0 8 * * MON',
    },
    {
      name: this.translate.instant('presets.monthly'),
      value: '0 8 1 * *',
    },
    {
      name: this.translate.instant('presets.weekdaily'),
      value: '0 8 * * MON,TUE,WED,THU,FRI',
    },
  ];

  idleTimes = this.idleTimesService.idleTimesProduction;

  clusterStrategies = [
    {
      name: this.translate.instant('cluster.form.strategies.self'),
      value: 'self',
    },
    {
      name: this.translate.instant('cluster.form.strategies.any'),
      value: 'any',
    },
    {
      name: this.translate.instant('cluster.form.strategies.same_node'),
      value: 'same_node',
    },
    {
      name: this.translate.instant('cluster.form.strategies.least_jobs'),
      value: 'least_jobs',
    },
    {
      name: this.translate.instant('cluster.form.strategies.none'),
      value: 'none',
    },
  ];
  timezones: SelectOption[] = [];

  constructor(
    protected store: Store<AppState>,
    protected notify: NotifyService,
    protected translate: TranslateService,
    private idleTimesService: IdleTimesService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationDialog: ConfirmationDialogService,
    private timezonesResource: TimezonesResource,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.timezonesResource.query().subscribe((timezonesResponse) => {
      this.timezones = moment.tz
        .names()
        .filter((name) =>
          timezonesResponse.find(
            (timezone) =>
              (timezone.id === name || timezone.id.includes(name) || name.includes(timezone.id)) && name !== 'UTC',
          ),
        )
        .map((name) => {
          const offset = moment.tz(name).format('Z');
          const formattedOffset = offset.replace(/^(-?)(\d{2})(\d{2})$/, '$1$2:$3');
          return {
            name:
              name.includes('Etc/') || name.includes('UTC') || name.includes('GMT') || name.includes('UCT')
                ? `UTC`
                : `(UTC${formattedOffset}) ${name.replaceAll('_', ' ')}`,
            value: name,
            offset: parseInt(offset.replace(':', '')),
          };
        })
        .sort((a, b) => {
          // if (a.name === 'UTC') return -1;
          // if (b.name === 'UTC') return 1;
          return a.offset - b.offset;
        })
        .map(({ name, value }) => ({ name, value }));

      if (!this.item.current_time_zone) {
        const matchingZone = this.timezones.find(
          (zone) =>
            zone.name === window['DEFAULT_TIMEZONE_NAME'] ||
            zone.name.includes(window['DEFAULT_TIMEZONE_NAME']) ||
            window['DEFAULT_TIMEZONE_NAME'].includes(zone.name),
        );

        this.setTimezone(matchingZone.value as string);
      }

      setTimeout(() => {
        this.start_hour_value = this.item.start_at
          ? this.item.start_at.endsWith('Z')
            ? moment.utc(this.item.start_at).format('HH:mm')
            : moment(this.item.start_at).format('HH:mm')
          : moment().tz(this.item.current_time_zone).format('HH:mm');
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item && changes.item.currentValue) {
      this.selectUnit(this.item.interval_unit);
      this.selectPreset(this.item.schedule_expression);

      this.item.schedule_message = this.item.schedule_message === null ? null : this.item.schedule_message;
      this.item.schedule_type = this.item.schedule_type === undefined ? 'interval' : this.item.schedule_type;
      if (!this.item.task) {
        this.item.task = {
          name: '',
          type: ClusterTypes.production,
          nodes: 1,
          terminate_on_idle: true,
          time_to_idle: IdleTimeValues.TenMinutes,
          reuse_cluster_strategy: 'any',
          packages: [],
        };
      }
      this.item.task.type = !this.item.task.type === undefined ? 'production' : this.item.task.type;
      this.item.task.reuse_cluster_strategy =
        this.item.task.reuse_cluster_strategy === undefined
          ? this.item.reuse_cluster_strategy || 'any'
          : this.item.task.reuse_cluster_strategy;

      if (this.item.task.packages && this.item.task.packages.length) {
        this.packagesStep = { ...this.packagesStep, valid: true };
      }

      this.setFormStepTags();
      this.setClusterStepTags();
    }
  }

  ngAfterViewInit() {
    this.setFrequencyMessage(true);
    this.item.cron_validity = true;
  }

  setFormStepTags() {
    const tags: Tag[] = [];
    if (this.item.schedule_type === 'interval') {
      tags.push(getTag('Repeat every', `${this.item.interval_amount} ${this.item.interval_unit}`));
    } else {
      tags.push(getTag('Cron', this.item.schedule_expression));
    }

    tags.push(getTag('Starts at', moment(this.item.start_at).utc().format('HH:mm UTC')));
    tags.push(getTag('', `${this.item.overlap ? 'Allow' : 'Do not allow'} concurrent schedule executions`));

    this.formStep = { ...this.formStep, tags };
  }

  updatePackages(packages: Package[]) {
    this.item.task.packages = packages;
    this.setPackagesStepTags(packages.length);
  }

  setPackagesStepTags(packagesLength: number) {
    const tags: Tag[] = [
      getTag(
        'Executes',
        `${
          this.item.task.packages.length + (this.item.task.packages.length > 1 ? ' packages' : ' package')
        } in parallel`,
      ),
    ];
    this.packagesStep = { ...this.packagesStep, tags, valid: !!packagesLength };
  }

  setClusterStepTags() {
    const tags = [];
    tags.push(getTag('Cluster', this.item.task.nodes + (this.item.task.nodes > 1 ? ' nodes' : ' node')));

    if (this.item.task.terminate_on_idle) {
      tags.push(getTag('Automatically terminated after', this.getTimeToIdle(this.item.task.time_to_idle)));
    }

    tags.push(
      getTag('Reuse', this.getClusterReuse(this.item.task.reuse_cluster_strategy || this.item.reuse_cluster_strategy)),
    );

    this.clusterStep = { ...this.clusterStep, tags };
  }

  getClusterReuse(reuse_cluster_strategy): string {
    return (this.clusterStrategies.find((item) => item.value === reuse_cluster_strategy) || {}).name || '';
  }

  getTimeToIdle(time_to_idle): string {
    return (this.idleTimes.find((item) => item.value === time_to_idle) || {}).name || '';
  }

  toggleStatusChange() {
    this.item.status = this.item.status === 'enabled' ? 'disabled' : 'enabled';
  }

  selectUnit(unitValue) {
    this.currentUnit = this.units.find((unit) => unit.value === unitValue) || this.units[1];
    this.item.interval_unit = unitValue;
  }

  selectPreset(cronExpression) {
    this.item.schedule_expression = cronExpression;
  }

  setStartAt() {
    this.item.start_at = this.getScheduleStartHour();
    this.setFormStepTags();
  }

  getScheduleStartHour() {
    const minutesHours = this.start_hour_value.split(':');
    const startHour = minutesHours[0];
    const startMinute = minutesHours[1];
    const starts_at = new Date();
    return moment.utc(starts_at).hour(Number(startHour)).minute(Number(startMinute)).second(0).toISOString();
  }

  resetCronValidity(valid) {
    this.item.cron_validity = valid;
    this.form.controls.cron.setErrors(valid ? null : { incorrect: true });
    this.formStep = { ...this.formStep, valid };
  }

  setFrequencyMessage(skipValidation = false) {
    this.item.schedule_message = '';
    try {
      this.item.schedule_message = cronstrue.toString(this.item.schedule_expression.toUpperCase());
      if (!skipValidation) {
        this.resetCronValidity(true);
      }
    } catch (e) {
      if (!skipValidation) {
        this.resetCronValidity(false);
      }
    }
  }

  validateCron() {
    if (
      this.item.schedule_expression &&
      this.item.schedule_message &&
      this.item.schedule_message !== this.translate.instant('Schedule.labels.invalid_cron_message')
    ) {
      const parsed_expression: string[] = this.item.schedule_expression.toUpperCase().split(' ');
      const cronIsValid = !(
        this.item.schedule_message.includes('undefined') ||
        !validateCronValues(parsed_expression) ||
        parsed_expression.length > 5 ||
        parsed_expression.includes('?')
      );

      if (cronIsValid) {
        this.resetCronValidity(true);
      } else {
        this.resetCronValidity(false);
        this.item.schedule_message = this.translate.instant('Schedule.labels.invalid_cron_message');
      }
    } else {
      this.resetCronValidity(false);
      if (this.item.schedule_expression && this.item.schedule_expression.length > 0) {
        this.item.schedule_message = this.translate.instant('Schedule.labels.invalid_cron_message');
      }
    }
  }

  saveScheduleClick(schedule: Partial<Schedule>) {
    if (schedule.status === 'enabled') {
      const dialogRef = this.confirmationDialog.openDialog({
        title: schedule.id ? 'Save schedule' : 'Create schedule',
        hint: 'The schedule will be deployed and jobs will start running as scheduled. Alternatively, you can toggle the status to inactive and revisit it later.',
        yes: schedule.id ? 'Save schedule' : 'Create schedule',
        no: 'Cancel',
        item: {
          ...schedule,
          name: schedule.name || 'Untitled schedule',
        },
        itemType: ItemType.schedule,
        type: DialogType.warning,
        hideIcon: true,
      });
      dialogRef.afterClosed().subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.saveSchedule(schedule);
        }
      });
    } else {
      this.saveSchedule(schedule);
    }
  }

  saveSchedule(schedule: Partial<Schedule>) {
    const newSchedule: Partial<Schedule> = { ...schedule, task: { ...schedule.task } };
    const params: ListParams = {};

    newSchedule.reuse_cluster_strategy = schedule.task.reuse_cluster_strategy;
    newSchedule.start_at = this.getScheduleStartHour();

    if (schedule.schedule_type === 'interval') {
      newSchedule.start_at = this.getScheduleStartHour();
    }

    if (schedule.schedule_type === 'cron') {
      newSchedule.start_at = new Date().toISOString();
    }

    newSchedule.task.packages = newSchedule.task.packages.map((packageItem) => ({
      variables: packageItem.overrideVariables ? packageItem.variables : undefined,
      package_id: packageItem.id,
      paper_trail_package_version: packageItem.paper_trail_package_version,
    }));

    if (schedule.id) {
      params.name = schedule.name;
      if (schedule.description) {
        params.description = schedule.description;
      }
      this.store.dispatch(updateSchedule({ schedule: newSchedule, params, scheduleId: schedule.id }));
    } else {
      this.store.dispatch(saveSchedule({ schedule: newSchedule }));
    }

    this.router.navigate(['./'], { relativeTo: this.route });
  }

  onTimeChange(value: string) {
    const newValue = value.replace(/[a-zA-Z]/g, '');
    this.start_hour_value = newValue;
    this.timepickerInput.nativeElement.value = newValue;
    this.setFormStepTags();
  }

  setTimezone(timezone: string) {
    this.item.current_time_zone = timezone;
    this.setFormStepTags();
  }

  protected readonly REST_API_RESPONSE_TYPES = REST_API_RESPONSE_TYPES;
}
