import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ConnectionGroup } from '../connection.models';
import { ConfirmationDialogService } from '../../common/services/confirmation-dialog.service';
import { AppState } from '../../store';
import { ItemType } from '../../common/components/lists/list-item-snippet.component';
import { deleteConnectionGroup } from '../store/connection-groups.actions';
import { NotifyService } from '../../common/services/notify.service';
import { selectAccountPermissions } from '../../account/store/account.selectors';
import { CustomMiddleClickEvent } from '../../common/helper/global-types.helper';
import { ChangeStateService } from '../../common/services/change-state.service';

@Component({
  selector: 'connection-group-list-item',
  template: `
    <div class="generic-list-item slider-animation connections" [ngClass]="{ 'closed-animation': item.isRemoved }">
      <div class="connection-list-item connection-group-list-item" id="connection-group-{{ item.id }}">
        <div class="connection-name">
          <div>
            <strong>
              <a
                (click)="showConnectionGroup(item, $event)"
                (auxclick)="showConnectionGroup(item, { isMiddleClick: true })"
                [matTooltip]="item.name"
                matTooltipPosition="right"
                matTooltipClass="right wide"
                >{{ item.name }}</a
              >
            </strong>
          </div>
        </div>
        <div class="connection-type">
          <span>
            {{ item.description }}
          </span>
        </div>
        <div class="connections-count">
          {{ 'generic-list.connections.connections_count_value' | translate: { count: item.connections.length } }}
        </div>
        <div class="connection-owner">
          <a ui-sref="app.account.settings.account.member(item.owner)">{{ item.modified_by.name }}</a>
        </div>
        <div class="connection-date">
          <span [matTooltip]="item.updated_at | xpDateUTC" matTooltipPosition="above" matTooltipClass="above">{{
            item.updated_at | xpPrettyDate
          }}</span>
        </div>

        <div class="dropdown xp-dropdown-full" *ngxPermissionsOnly="'updateConnectionGroup'">
          <i class="fa fa-ellipsis-h" [matMenuTriggerFor]="dropdown"></i>
        </div>
        <mat-menu #dropdown="matMenu">
          <li mat-menu-item (click)="editConnectionGroup(item)" *ngxPermissionsOnly="'updateConnectionGroup'">
            <span>{{ 'connection-group.generic-list.item.actions.edit' | translate }}</span>
          </li>
          <li mat-menu-item (click)="deleteConnectionGroup(item)" *ngxPermissionsOnly="'deleteConnectionGroup'">
            <span class="text-danger">{{ 'connection-group.generic-list.item.actions.delete' | translate }}</span>
          </li>
        </mat-menu>
      </div>
    </div>
  `,
})
export class ConnectionGroupListItemComponent {
  @Input() item: ConnectionGroup;
  @Output() edit = new EventEmitter<ConnectionGroup>();

  accountPermissions$ = this.store.select(selectAccountPermissions);

  constructor(
    private translate: TranslateService,
    private confirmationDialog: ConfirmationDialogService,
    private store: Store<AppState>,
    private notify: NotifyService,
    private changeStateService: ChangeStateService,
  ) {}

  notifyInfo() {
    this.notify.info('Copied!');
  }

  changeState(stateName: string, event: MouseEvent | CustomMiddleClickEvent): void {
    this.changeStateService.changeState(stateName, event);
  }

  showConnectionGroup(item: ConnectionGroup, event: MouseEvent | CustomMiddleClickEvent) {
    this.changeState(`connections/groups/${item.id}`, event);
  }

  editConnectionGroup(item: ConnectionGroup) {
    this.edit.emit(item);
  }

  deleteConnectionGroup(item: ConnectionGroup): void {
    const dialogRef = this.confirmationDialog.openDialog({
      title: this.translate.instant('connection-group.generic-list.item.modals.confirm.title'),
      hint: this.translate.instant('connection-group.generic-list.item.modals.confirm.hint'),
      yes: this.translate.instant('connection-group.generic-list.item.modals.confirm.yes'),
      no: this.translate.instant('connection-group.generic-list.item.modals.confirm.no'),
      item,
      itemType: ItemType.connectionGroup,
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.store.dispatch(deleteConnectionGroup({ groupId: item.id }));
      }
    });
  }
}
